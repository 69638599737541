import {combineReducers} from 'redux';
import settings from './settings/Reducer';
import authenticationReducer from './authentication/Reducer';
import meReducer from './me/Reducer';

const Reducers = combineReducers({
  settings,
  authenticationReducer,
  meReducer,
});

export default Reducers;

import {ADD_AUTH, REMOVE_AUTH} from '../constants/';

const INIT_STATE = JSON.parse(localStorage.getItem('authAdminPanel'));

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_AUTH:
      return {
        ...state,
        ...action.payload,
      };
    case REMOVE_AUTH:
      return {};
    default:
      return state;
  }
};

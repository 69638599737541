import React, {Suspense, lazy} from 'react';
import ReactDOM from 'react-dom';
import Spinner from '_core/loaders/Spinner';
import './assets/scss/style.scss';
import {Provider} from 'react-redux';
import Store from '_redux/Store';

const App = lazy(
  () =>
    new Promise((resolve) => {
      setTimeout(() => resolve(import('./app')), 0);
    }),
);

ReactDOM.render(
  <Suspense fallback={<Spinner />}>
    <Provider store={Store}>
      <App />
    </Provider>
  </Suspense>,
  document.getElementById('root'),
);
